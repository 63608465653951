import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginBase from '../views/Login/Base.vue';
import LoginLogin from '../views/Login/Login.vue';
import PortalBase from '../views/Base.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: LoginBase,
    children: [
      {
        path: '',
        name: 'login',
        component: LoginLogin,
        beforeEnter: (to, from, next) => {
          if (isLoggedIn()) next({name: 'dashboard'});
          else next();
        }
      }
    ]
  },
  {
    path: '/portal',
    component: PortalBase,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "portal" */ '../views/Dashboard.vue')
      },
      {
        path: 'contracts',
        component: () => import(/* webpackChunkName: "portal" */ '../views/contracts/Contracts.vue'),
        children: [
          {
            path: '',
            name: 'contracts-overview',
            component: () => import(/* webpackChunkName: "portal" */ '../views/contracts/Overview.vue')
          },
          {
            path: 'details/:id',
            name: 'contract-details',
            component: () => import(/* webpackChunkName: "portal" */ '../views/contracts/ContractDetails.vue')
          }
        ]
      },
      {
        path: 'invoices',
        component: () => import(/* webpackChunkName: "portal" */ '../views/invoices/Invoices.vue'),
        children: [
          {
            path: '',
            name: 'invoices-overview',
            component: () => import(/* webpackChunkName: "portal" */ '../views/invoices/Overview.vue')
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!isLoggedIn() && to.name !== 'login') {
    if (from.name === 'login') return {name: 'login'};
    else next({name: 'login'});
  }
  else next();
})

function isLoggedIn(): boolean {
  const cookieSessionValue = document.cookie.split('=')[1];
  return (cookieSessionValue !== '' && cookieSessionValue !== undefined);
}

export default router
