<template>
  <div>
    <h1>Page not found</h1>
    <p>The requested page couldn't be found.</p>
    <p>Route: <code>{{ $route.path }}</code></p>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'mlc-view-notfound',
})
</script>