<template>
  <v-form
      v-model="state.isValid"
      ref="frm"
      lazy-validation
      @submit.prevent="onSubmit"
      @input="onInput"
  >
    <form-error ref="frmErr" />
    <slot/>
  </v-form>
</template>

<script lang="ts">
import Vue from "vue";
import i18n from "@/i18n";
import MLC, {MlcRequestOptions} from "@/modules/MLC";
import {RawLocation} from "vue-router";
import FormError from './FormError.vue'
import {EventBus} from "@/modules/EventBus";

export const enum FormMode {
  New,
  Edit
}

interface KeyValue {
  [key: string]: string|KeyValue;
}

interface FormData {
  state: {
    isValid: boolean,
    isProcessing: boolean,
    isDeleting?: boolean
  },
  errors: KeyValue,
}

export interface FormOptions {
  url: string
  urlData?: { [key: string] : string | number | boolean }
  urlDelData?: { [key: string] : string | number | boolean }
  data: FormData
  mode: FormMode
  serialize?: (data: any) => any
  delete?: (data: any) => (Promise<boolean> | boolean)
  next?: RawLocation
  timeout?: number,
  objectId?: string
}

export class Form {
  private _url : string
  private readonly _urlData: { [key: string] : string | number | boolean }
  private readonly _urlDelData: { [key: string] : string | number | boolean }
  private readonly _mode: FormMode
  private _data: FormData
  private _serialize?: (data: any) => any
  private _delete?: (data: any) => (Promise<boolean> | boolean)
  private _next?: RawLocation
  private _timeout?: number
  private _objectId?: string

  constructor(opts: FormOptions) {
    this._url = opts.url
    this._urlData = opts.urlData ? opts.urlData : { }
    this._urlDelData = opts.urlDelData ? opts.urlDelData : {}
    this._mode = opts.mode
    this._data = opts.data
    if (opts.serialize) this._serialize = opts.serialize
    if (opts.delete) this._delete = opts.delete
    if (opts.next) this._next = opts.next
    if (opts.timeout) this._timeout = opts.timeout
    if (opts.objectId) this._objectId = opts.objectId
  }

  setUrl(url: string): void {
    this._url = url
  }

  async load(callback: (j: any, data: any) => void): Promise<void> {
    this._data.state.isProcessing = true
    const response = await MLC.fetchJson(this._url, this._urlData, { method: 'GET' });
    if (process.env.NODE_ENV !== 'production') await new Promise(r => setTimeout(r, 500));    // DEBUG
    if (response.success) {
      callback(response.data, this._data)
    }
    this._data.state.isProcessing = false
  }

  async send() : Promise<boolean|Record<string, unknown>> {
    if (!this._serialize) return false
    const data = this._serialize(this._data)
    //const j = await LC.fetchJson(this._url, data, { method: this._mode === FormMode.Edit ? 'PATCH' : 'POST' })
    // for development: use <name>.patch.json or <name>.post.json for requests
    const opts = { method: process.env.NODE_ENV === 'production' ? (this._mode === FormMode.Edit ? 'PATCH' : 'POST') : 'GET' } as MlcRequestOptions
    if (this._timeout) opts.timeout = this._timeout
    opts.quiet = true
    let j:any
    j = await MLC.fetchJson(process.env.NODE_ENV === 'production' ? this._url : (this._mode === FormMode.Edit ? this._url.replace(/\.json$/, '.patch.json') : this._url.replace(/\.json$/, '.post.json')),
        data,
        opts
    )
    if (process.env.NODE_ENV !== 'production') await new Promise(r => setTimeout(r, 500));    // DEBUG
    if (!j) return false
    if (j === true || j.status === 204) {
      return true;
    } else if (j.success) {
      return j;
    } else {
      // error occurred
      if (j.status === 409) {
        // "ObjectExistsException" (HTTP 409) - maybe remap to corresponding input element
        if (this._objectId) j["detail"] = { [this._objectId]: j["detail"] }
      }
      if (j.detail) {
        if (typeof j.detail === 'string') {
          // send error to message bus
          EventBus.$emit('error.push', j.detail)
        } else {
          // show input errors
          let err = {} as {[key:string]:any}; // temporary object to assemble errors
          Object.keys(j.detail).forEach(key => {
            // ToDo: remember/mark which input fields have been marked with error, print all other fields in error box!
            if (err[key]) {
              // merge nested structures
              err[key] = { ...(err[key] as any), ...j.detail[key] };
            } else {
              err[key] = j.detail[key];
            }
          })
          // now replace error object atomically
          Vue.set(this._data, 'errors', err);
        }
      } else {
        console.error('Error: ', j)
      }
      return false
    }
  }

  async del() : Promise<boolean> {
    if (!this._delete) return false
    const confirm = await this._delete(this._data)
    if (!confirm) return false
    // delete record
    const j = await MLC.fetchJson(this._url, this._urlDelData, { method: 'DELETE' })
    if (process.env.NODE_ENV !== 'production') await new Promise(r => setTimeout(r, 500));    // DEBUG
    if (!j) return false
    if (j.success || j === true || j.status === 204) {
      return true
    } else {
      // error occurred
      if (j.detail) {
        if (typeof j.detail === 'string') {
          // show "alert" box
          alert(j.detail)
          // frmErr.show(j.detail)
        }
      } else {
        console.error('Error: ', j)
      }
      return false
    }
  }

  check(required: boolean, opts: { min?: number, max?: number, id?: string, regex?: RegExp }) : {(v: string) : string|boolean;}[] {
    return [
      v => (!required || (Array.isArray(v) && v.length > 0) || (typeof v === 'string' && v !== '')) || (typeof v === 'number') || (typeof v === 'object') || String(i18n.t('rules.isRequired')),
      v => {
        if (!required && !v) return true
        // if HTML object ID is submitted, read constraints directly from that object
        let isFloat = false
        if (opts.id) {
          const el = document.getElementById(opts.id) as HTMLInputElement
          if (el && el.min) opts.min = Number.parseInt(el.min)
          if (el && el.max) opts.max = Number.parseInt(el.max)
          if (el && el.step && Number(el.step) % 1 !== 0) isFloat = true
        }
        if (opts.min !== undefined || opts.max !== undefined) {
          // if "min" or "max" is specified, this must be a number:
          if (!isFloat && ! /^-?\d+$/.test(v)) return String(i18n.t('rules.onlyNumbers'))
          if (isFloat && ! /^-?\d+(\.\d+)?$/.test(v)) return String(i18n.t('rules.onlyNumbers'))
          if (opts.min !== undefined && parseInt(v) < opts.min) return String(i18n.t('rules.minValue')).replace('%d', String(opts.min))
          if (opts.max !== undefined && parseInt(v) > opts.max) return String(i18n.t('rules.maxValue')).replace('%d', String(opts.max))
        }
        return true
      },
      v => {
        if (!opts.regex) return true;
        const match = opts.regex.exec(v);
        if (!match) return true
        return String(i18n.t('rules.invalidCharacter')).replace('%s', v[match.index]).replace('%d', String(match.index+1))
      },
      // check if HTML input validation is in error state
      // PROBLEM: if input has property "type='number'", then the value of the object will always be
      // an empty string ("") if input is not a number (e.g. "xx"). An empty string would possibly be allowed
      // (when "required=false"), so we need to check the HTML input validity state:
      v => {
        if (!opts.id) return true
        const el = document.getElementById(opts.id) as HTMLInputElement
        if (el && el.validity && el.validity.badInput) return String(i18n.t('rules.invalidInput'))
        return true
      }
    ]
  }

  reset(name: string | string[] | null) {
    if (name && Array.isArray(name)) {
      let err = this._data.errors
      for (let n of name) {
        if (err[n]) {
          if (typeof err[n] === 'object') {
            err = err[n] as KeyValue
            continue
          } else {
            // leaf node
            err[n] = ''
          }
        } else {
          // no child found...
          return
        }
      }
    } else if (name) {
      //if (this._data.errors[name] && this._data.errors[name].length > 0) this._data.errors[name] = '';
      if (typeof this._data.errors[name] === 'string') this._data.errors[name] = '';
    } else {
      //this.showError = false;
      //this.errorMsg = '';
    }
  }

}

export default Vue.extend({
  components: {
    FormError
  },
  data: () => ({
    old_state: {
      isValid: true,
      isProcessing: false,
      isDeleting: false
    },
  }),
  props: {
    state: {
      type: Object,
      required: true
    },
    form: {
      type: Object as () => Form,
      required: true
    },
    next: {
      type: Object as () => RawLocation,
      required: false
    },
    onSuccess: {
      type: Function,
      required: false
    },
    onCancel: {
      type: Function,
      required: false
    },
    onInput: {
      type: Function,
      required: false
    }
  },
  provide: function() {
    return {
      state: this.state,
      onDelete: (this as any).onDelete,  // dirty workaround to not get "property onDelete does not exist..."
      onCancel: (this as any).onCancel
    }
  },
  methods: {
    validate(): boolean {
      return (this.$refs.frm as Vue & { validate: () => boolean }).validate();
    },
    onSubmit: async function() {
      console.log('onSubmit called');
      const frmIsValid = (this.$refs.frm as Vue & { validate: () => boolean }).validate();
      if (frmIsValid) {
        // ToDo: hide/remove error popup
        this.state.isProcessing = true
        let response = await this.form.send()
        console.log(response);
        this.state.isProcessing = false
        if (response === true || (response && response.success)) {
          if (this.onSuccess) {
            console.log('onSuccess condition');
            this.onSuccess(response)
          }
          if (this.next) {
            this.$router.push(this.next)
          }
        }
      }
    },
    onDelete: async function() {
      this.state.isDeleting = true
      let success = await this.form.del()
      this.state.isDeleting = false
      if (success && this.onSuccess) {
        this.onSuccess()
      }
      if (success && this.next) {
        this.$router.push(this.next)
      }
    }
    // ToDo: implement FormRules?
  },
})
</script>